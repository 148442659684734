
 .navbar_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 2rem;
    opacity: 1;
    right: 110%;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.7);
    
  }
  
  .navbar_menu .stylist_name {
    top: 0;
    z-index: 50;
    font-size: 5rem;
    margin-top: 0rem;
    word-spacing: 0rem;
    /* margin-bottom: 2rem; */
  
  }
  
  
  .navbar_menu .stylist_name::before {
    display: none;
  }
  
  .BurgerMenu__container {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    position: absolute;
    right: 0rem;
    top: 2.5rem;
  }
    
    .BurgerMenu__container i {
  
      background-color: wheat;
      width: 32px;
      height: 4px;
      margin: 4px;
      border-radius: 2px;
      transition:all ease 0.3s;
    }
    
  
    .open:nth-child(1) {
      transform: rotate(-45deg) translateY(8.5px) translateX(-8.5px);
    }
  
    .open:nth-child(2) {
      opacity: 0;
    }
  
    .open:nth-child(3) {
      transform: rotate(45deg) translateY(-8.5px) translateX(-8.5px);
    }
  
  
    .close:nth-child(1) {
      transform: rotate(0) translateY(0);
    }
  
    .close:nth-child(2) {
      opacity: 1;
    }
  
    .close:nth-child(3) {
      transform: rotate(0) translateY(0);
    }
  
  
  
  .navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: black;
    font-family: "Quintessential", cursive;
    position: sticky;
    top:0;
    z-index: 20;
    background-color: black;
    border-bottom: 2px solid wheat;
  }
  
  
  .navbar_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    position: relative;
    bottom: 0.3rem;
    /* box-shadow: 2px 4px 6px white; */

  }
  
  .navbar_logo {
    cursor: pointer;
    align-items: center;
    margin-left: 20px;
    flex-grow: 1;
  }
  
  .navbar_menu {
    display: flex;
  
    margin-right: 2rem;
    flex-grow: 1;
  }
  
  .navbar_links {
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    color: white;
    height: 100%;
    padding: 0;
    width: 100%;
    opacity: 1;
    font-size: 1.5rem;
    line-height: 6rem;
    margin-right: 1rem;
    font-family: Quintessential,cursive;
  }
  
    
  .navbar_logo {
    margin-left: 0px;
  }
  
  .navbar_menu.active {
    padding-top: 8rem;
    transform: translateY(-8.4rem);
    left: 0px;
    bottom: 8rem;
    opacity: 1;
    z-index: 1;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.92);
    min-height: 115rem; 
    position: fixed;
  }
  
  
  
  
  .BurgerMenu__container {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    z-index: 80;
  
  }
    .BurgerMenu__container i {
      background-color: wheat;
      width: 32px;
      height: 4px;
      margin: 4px;
      border-radius: 2px;
    }
  
    .open:nth-child(1) {
      transform: rotate(-45deg) translateY(8.5px) translateX(-8.5px);
    }
  
    .open:nth-child(2) {
      opacity: 0;
    }
  
    .open:nth-child(3) {
      transform: rotate(45deg) translateY(-8.5px) translateX(-8.5px);
    }
  
  
    .close:nth-child(1) {
      transform: rotate(0) translateY(0);
    }
  
    .close:nth-child(2) {
      opacity: 1;
    }
  
    .navbar_links.active {
      color: wheat;
    }
  
  
      @media screen and (min-width: 1000px) {
        
        .navbar_menu {
          position: absolute;
          top: 0;
          left: 0px;
          opacity: 1;
          z-index: 1;
          background-color: black;
          height: 8rem;
          display: flex;
          margin: auto;
          width: 100%;
          
        }
  
        .navbar_menu .stylist_name {
          font-size: 3.7rem;
          margin-top: 3.5rem;
          word-spacing: 0rem;
        }
        
        
        .navbar_menu .stylist_name::before {
          display: none;
        }
  
        .navbar_container {
          display: flex;
          flex-direction: row;
          padding-top: 10rem;
          /* box-shadow: 2px 4px 6px white; */
          
        }
  
        .BurgerMenu__container {
          display: none;
      }
  
      .navbar_menu {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        justify-content: center;
        /* left: 30%; */
        
      }
  
      .navbar_menu li {
        margin-left: 2%;
      }
  
      .navbar_links{
        font-size: 1.7rem;
        margin-top: 1rem;
        display: flex;
        padding: 1.5rem;
        width: 100%;
        cursor: pointer;
        opacity: 1;
        letter-spacing: 0.18rem;
        transition: all 0.3s ease-in-out;
      }
  
      .navbar_links:hover {
        color: rgb(255, 246, 228);
        font-size: 1.9rem;
        font-weight: 500;
      }    
  
    }
  