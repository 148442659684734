@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Unna:wght@400;700&display=swap');
/* Tarif page h2 */ 
@import url("https://fonts.googleapis.com/css2?family=Quintessential&display=swap");


@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');


/* Tarif page font */
@import url("https://fonts.googleapis.com/css2?family=Signika&display=swap");
/* Text home page */

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100&display=swap");
/* Video page title container */ 

@import url("https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap");
/* stylist name previous choice */
/*    font-family: 'Luxurious Roman', cursive; */

/* Fonts from files */
/* End font from files */
*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  color: inherit;
}

html {
  font-size: 62.5%;
  
}

*, h3 {
  font-family: "Signika", sans-serif;
}

p {
  font-family: "Luxurious Roman" !important;
  
}

h3 {
  font-size: 1.7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 0.5em;
}

h4, li {
  font-family: "Luxurious Roman" !important;

}

.no-underline {
  text-decoration: none;
}

body {
  background-color: black;
  /* background-image: linear-gradient(2deg, rgb(7, 7, 24) 0%, #515966 2%, rgb(7, 7, 24) 10%, rgb(7, 7, 24) 80%, #515966 96%, rgb(0, 0, 0) 98%, rgb(0, 0, 0) 100%); */
  color: white;
  
}
h3 br {
  line-height: 1.7rem;
  margin-bottom: 0!important;
}

strong {
  font-weight: 400;
}

.home_page {
  background-color: black;
  /* background-image: linear-gradient(2deg, rgb(7, 7, 24) 0%, #515966 1%, rgb(7, 7, 24) 3%, rgb(7, 7, 24) 90%, #4d5b70 98%, rgb(0, 0, 0) 100%); */

}



/* .page h1 {
  font-family: "Luxurious Roman", sans-serif;
} */

.home_first_frame {
  /* padding-top: 2rem; */
  padding-bottom: 0rem;
}

.second_frame {
  padding-top: 2rem;
  padding-bottom: 6rem;
}


.stylist_name {
  position: relative;
  text-align: center;
  margin: 0 auto 8rem auto;
  font-size: clamp(5rem, 3vw, 8rem);
  font-family: 'Italianno', cursive !important; 
  word-spacing: 2rem;
  color: rgb(221, 178, 98);
  font-weight: normal;
  font-style: italic;
}

.stylist_name a {
  font-family: 'Italianno', cursive;
  position: relative;
  bottom: 0.7rem;
}

.home_page .stylist_name {
  margin-top: calc(2rem + 2vw);
  margin-bottom: calc(0rem + 1vw);

}

/* .stylist_name::before {
  content: "";
  position: absolute;
  height: 2px;
  background-color: rgb(221, 178, 98);
  width: 70%;
  margin: 2rem auto;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 230px;
  top: 5.5rem;
} */

.home_page_hr {
  width: 90%;
  max-width: 100rem;
  margin: 12rem auto 6rem auto;
}


.hidden-mobile-screen {
  display: none;
}


 .video_content {
  margin-top: 3rem;
  margin-bottom: 5rem;

}




@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 wheat;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.introduce_video {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 8rem;
}




.introduce_video .youtube_video {
  display: flex;
  margin: 3rem auto 4rem auto;
  width: 90%;
  height: 50vw;
  border: 3px solid wheat;
  border-radius: 8px;
}

.page_separation {
  border: none;
  border-top: 1px double #ffe3aa;
  color: #ffe3aa;
  overflow: visible;
  text-align: center;
  height: 2px;
  width: 100%;
  margin: auto;
  /* max-width: 140rem; */
}
.page_separation:after {
  background: rgb(49, 44, 44);
  color: #ffe3aa;
  padding: 0 1px;
  position: relative;
  top: -13px;
}

.introduction {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: calc(4rem + 2vw);
  text-align: center;
  max-width: 90rem;
  font-weight: 100;
  
}

.introduction_title {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 2vw;
  position: relative;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.2rem;
  color: wheat;
}




/* Video Page background */ 

.video_page_body {
  background-color: black;
  /* background-image: linear-gradient(2deg, rgb(7, 7, 24) 0%, #515966 1%, rgb(7, 7, 24) 3%, rgb(7, 7, 24) 88%, #4d5b70 98%, rgb(0, 0, 0) 100%); */
  min-width: 100%;
}






@media screen and (min-width: 370px) {
  .introduction_title {
    font-size: 3.5rem;
  }
}

/* .introduction_title::before {
  content: "";
  position: absolute;
  height: 2px;
  background-color: wheat;
  font-weight: 700;
  left: 50%;
  transform: translate(-50%, -5px);
  width: 20rem;
  bottom: -1rem;
} */

.introduction p {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  font-family: "Signika", sans-serif;
  line-height: 2.3rem;
  font-style: italic;
  letter-spacing: 0.3px;

}

.introduction_list_item {
  line-height: 3rem;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  font-family: "Signika", sans-serif;
  line-height: 2.3rem;
}


.introduction_list_item li{
 
  margin-top: 1.4rem;
  font-style: italic;
  letter-spacing: 0.3px;

}

/* 
.second_frame:last-child {
  margin-top: 0;
} */

.introduction br {
  margin-bottom: 4rem;
}


/* Mobile in the future
#navigation {
  display: absolute;
  // display: sticky;
  // display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border-bottom: 4px solid #161d25;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 10;
  background-color: black;
  opacity: 0.9;
  padding-top: 25vh;

}
*/


.video_content {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 5rem;
}

.button_to_video_text {
  text-align: center;
  margin: 0rem auto 3rem auto;
  padding: 2rem 1.5rem;
  background-color: #000824;
  background-image: linear-gradient(120deg,  #737883 0%,rgb(255 255 255) 14%, rgb(255 255 255) 3%, rgb(190 190 223) 80%, #8f97a4 98%, rgb(255 239 239) 100%);
  border: 2px solid white;
  border-radius: 9px;
  box-shadow: 1px 3px 15px wheat;
  animation: pulse 2s infinite;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  font-family: "Signika";
  width: 90%;  
  max-width: 35rem;
  color: black;
}

.button_to_video_text:nth-child(1)::first-letter {
  text-transform: uppercase;
}


.video_content {
  width: 100%;
  max-width: 70rem;
  margin: auto;
}

.video_content_link {
  margin-top: 0rem;
  text-align: center;
}


.contact_firstname, .contact_lastname, .mail_container, .contact_number {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.message_container {
  margin: auto;
}

.contact_label_name, .contact_label_email, .contact_label_number {
  display: none;
}

@media screen and (min-width: 550px) {
  .hamburgur {
    left: 85%;
  }
}

@media screen and (min-width: 800px) {



  .hamburgur {
    display: none;
  }

  .home_page .stylist_name {

  margin-bottom: 1vw;

  }

  .stylist_name {
    font-size: 9rem;
    margin-bottom: calc(8rem + 5vw);
    margin-left: auto;
    margin-right: auto;

  }

  .stylist_name::before {
    top: 8.3rem;
    width: 80%;
    max-width: 30rem;
    height: 1px;
  }





  .hidden-mobile-screen {
    display: inline-block;
  }
  

  .introduction_title {
    margin-top: 0rem;
    margin-bottom: calc(1rem +2vw);
  }

  .introduction br {
    margin-bottom: 2rem;
  }
  


  #navigation {
    display: flex;
    flex-direction: row;
    height: auto;
    padding-top: 0rem;
    justify-content: center;
  }


  .introduce_video {
    width: 100%;
    margin: auto;
  }
  .introduce_video .youtube_video {
    width: 61%;
    height: 30vw;
    border: 2px solid wheat;
  }

  .introduction p {
    font-size: 1.9rem;
    line-height: 3.8rem;
  }
  
  .introduction_list_item {
    font-size: 1.9rem;
    line-height: 3.8rem;
  }

}
