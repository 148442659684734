.not_found_page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.not_found_page_title {
    font-size:clamp(2.6rem, 2.5vw, 3.4rem);
    font-family: "Quintessential", cursive;
    color: wheat;


    
}