.phototest_page {
    min-height: 100vh;
    width: 100%;
    /* background-image: linear-gradient(2deg,#515966 0%, #515966 2%, rgb(0, 0, 0) 10%, rgb(0, 0, 0) 80%, #000000 96%, #515966  98%, #515966 100%); */
    background-color: black;
    margin-top: 0;
    padding-top: 6rem;
    padding-bottom: 3rem;
}

.photo_page {
    margin-top: 0;
}


.phototest_page::before {
	position: absolute;
	top: -30%;
	left: -150%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)!important;
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)!important;
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.phototest_page::before {
	-webkit-animation: shine 0.7s 0.3s ease-in-out forwards; 
	animation: shine 0.7s 0.3s ease-in-out forwards;
}



@keyframes shine {
	98% {
		left: 100%;
        opacity: 1;
	} 
    100% {
        opacity: 0;
    }
  }

/* 
@-webkit-keyframes shine {
	100% {
		left: 150%;
	}
} */



.phototest_page .photos_container {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    margin: auto;    
   
    
}



@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

.box-image {
    aspect-ratio: 12 / 24;
    /* aspect-ratio: 15 / 30; */
    max-width: 30rem;
    min-width: 20rem;
    min-height: 25rem;
    width: 25%;
    overflow: hidden;
    border-top: 1px solid wheat;
    border-left: 1px solid wheat;
    border-right: 1px solid wheat;
    border-bottom: 1px solid wheat;
    opacity: 1;

    
}

.box-label {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid wheat;
    border-left: 1px solid wheat;
    border-bottom: 1px solid wheat;
    overflow: hidden;
    background-color: black;

}

.photo_label {
    color: wheat;
    width: 100%;
    font-family: 'Italianno', cursive;
    font-size: clamp(2.5rem, 3.2vw, 1.6rem);
    font-weight: 400;
    letter-spacing: 3px;
    overflow: hidden;

}


.box-image img {
    width: 100%;
    height: 100%;
    display: block;
    transition: all ease-in-out 0.3s;
    overflow: hidden;
    object-fit: cover;
}


.box-image img:hover {
    scale: 1.02;
    -webkit-animation: flash 1s;
	animation: flash 1s;
}


.fill-width {
    height: 2px;
    min-height: 0;
}

/* 
.photos_container .box-image {
    width: 24%;
} */


@media screen and (max-width: 650px) {

    .phototest_page .photos_container {
        width: 95%;

    }


}


@media screen and (max-width: 548px) {

    .phototest_page .photos_container {
        width: 97%;
        

    }

    
    .box-image {
        min-width: 15rem;
        width: 45%;
       
    }


}



@media screen and (max-width: 450px) {

    .phototest_page .photos_container {
        row-gap: 3.5em;
        margin-bottom: -5rem;
    }

    .box-image {
        aspect-ratio: 12 / 24;
        min-width: 10rem;
        max-width: 45%;
        width: 90%;
    }

    .fill-width {
        height: 0px;
        min-height: 0;
    }
    


}