.shuffle_style {
    /* position: absolute; */
    visibility: visible;
    opacity: 1;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, opacity;
}

.shuffle_style_container {
    position: relative;
    overflow: hidden;
    /* height: 1093.85px; */
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.filter-options li p {
    @apply inline-block text-[13px] uppercase py-[7px] px-[0.4] sm:px-3 cursor-pointer transition-all duration-300 rounded font-medium;
}

.filter-options li:hover p,
.filter-options li.active p,
.filter-options li:active p,
.filter-options li:focus p {
    background-color: hsla(39, 77%, 83%, 0.1);
    color: wheat;
}

.filter-box {
    display: none;
}

.category_list li p {
    padding: auto 0!important;
}

/* .tarif_card:nth-child(1)::before { */

/* .gallery-container {
    min-height: 80vh;
    transform-origin: center;
    animation-fill-mode: forwards;
    animation-duration: 5s;
}

@keyframes height-component {
    from{
        height: auto;
    }
  
    to{
        height: auto;
    }
  }
 */


