/* .slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    transform-origin: bottom;
}

.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    transform-origin: top;
} */

input,
textarea {
  border: 1px solid #515151 !important;
}

.bg-contact-hero {
  background-position-y: -10rem;
  position: relative;
  background-size: cover;
  min-height: 100vw;
}

.contact-jumbo {
  background-image: url("/public/castlefashion.webp");
}

@media screen and (min-width: 380px) {
  .bg-contact-hero {
    background-position-y: -16rem;
  }
}

@media screen and (min-width: 600px) {
  .bg-contact-hero {
    min-height: 38rem;
    background-position-y: -24rem;
  }
}

@media screen and (min-width: 780px) {
  .bg-contact-hero {
    min-height: 38rem;
    background-position-y: -49rem;
  }
}

@media screen and (min-width: 900px) {
  .bg-contact-hero {
    background-position-y: 97%;
    background-size: cover;
    position: relative;
  }
}

@media screen and (min-width: 2000px) {
  .contact-page {
    margin: auto;
    min-width: 206rem;
    display: flex;
    justify-content: center;
  }

  #exampleCheck96 {
    height: auto;
    min-height: 1px;
  }

  .contact-page input {
    min-height: 8rem;
  }

  .contact-page textarea {
    min-height: 24rem;
  }
}
