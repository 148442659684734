

.App {
    text-align: center;
  }
  
  .page {
    min-height: calc(100vh - 12.5rem);
  }
  

  /* .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #61dafb;
  } */



.border-none {
  border: none!important;
}





@media screen and (max-width: 1436px) {

  .display-l-none  {
    display: none;
 }
}

@media (min-width: 1128px) and (max-width: 1784px) {
  
  .display-m-none  {
    display: none;
 }
}


@media screen and (max-width: 900px) {

  .display-s-none  {
    display: none;
}

}


