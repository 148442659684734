


.tarifs_page {
    min-height: calc(100vh - 13rem);
    padding-top: 3vw;
}

.title_page {
    position: relative;
    text-align: center;
    margin: 7rem auto 8rem auto;
    font-size: clamp(4.5rem, 8vw, 6rem);
    font-family: "Italianno", cursive;
    word-spacing: 2rem;
    color: rgb(221, 178, 98);
    font-weight: normal;
    font-style: italic;
}

.tarifs_page .title_page {
    animation-name: opacity-submenu-component;
    animation-duration: 0.5s;
}

.title_page::before {
    content: "";
    position: absolute;
    height: 2px;
    background-color: rgb(221, 178, 98);
    width: 20%;
    margin: 2rem auto;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 12rem;
    top: 3.6rem;
} 

.prestation_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.prestation_container_card {
    position: relative;
    display: flex;
    padding: 10vh 0rem 1rem 0rem;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

/* .tarif_page_prestation_container_card_price {
    bottom: 2rem;
} */

.prestation_container_card p {
    margin: 1.4rem 0;
    letter-spacing: 0.35px;
}

.tarif_card {
    width: 100%;
    min-width: 30.5rem;
    max-width: 35rem;
    position: relative;
    text-align: left;
    border: 1px solid wheat;
    padding: 4rem 0rem 2.5rem 0;
    object-fit:fill;
    background-size: 30rem 30rem;
    color: #ffffff;
    font-weight: 600;
    z-index: 5;
    margin-bottom: 4rem;
    opacity: 0;
    transform-origin: center;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: opacity-submenu-component;
    animation-duration: 0.5s;
    
}

    .tarif_card:nth-child(1) { 
        animation-delay: 0.2s;  
    }

    .tarif_card:nth-child(2) {
        animation-delay: 0.4s;
    }

    .tarif_card:nth-child(3) {
        animation-delay: 0.6s;

    }

    .tarif_card li {
    z-index: 5;

    }

.tarif_card::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}


.tarif_card::before {
    opacity: 0.34;
    
}

.tarif_card:nth-child(2)::before,
.tarif_card:nth-child(3)::before {
    background-size: 110% ;
    background-repeat: no-repeat;
    background-position: 20%;
}

.tarif_card:nth-child(1)::before {
    background-image: url("./../../images/tarifs_page/garde-robe.jpg");
    object-fit: contain;
    background-size: 120% 100%;
    background-position: 50%;
}

.tarif_card:nth-child(2)::before {
    background-image: url("./../../images/tarifs_page/tailor-tarif-card.jpg");
}

.tarif_card:nth-child(3)::before {
    background-image: url("./../../images/tarifs_page/personnal-shopper.jpg");
}

.tarif_card h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family: 'Luxurious Roman', serif;
    /* font-family: 'Quintessential', serif; */
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
}

.tarif_card ul {

    display: flex;
    flex-direction: column;
}

.tarif_card ul li {
    display: flex;
    flex-direction: row;
    line-height: 0rem;
    margin-top: 0;
}

.tarif_card li p {
    /* font-family: 'Quintessential', serif; */
    line-height: 1.5rem;
    margin-top: 0.5rem;
    font-size: clamp(1.2rem, 1.5vw, 1.4rem);
    font-family: 'Luxurious Roman', serif;

}


.price_check {
    color: rgb(221, 178, 98);
    font-size: 2rem;
    margin-left: 0.8rem;
    margin-right: 4px;
    position: relative;
    top: 2px;
}


.tarif_page_prestation_container_card_price {
    text-align: center;
    max-width: 150rem;
}

.tarif_page_prestation_container_card_price > *, .tarif_page_prestation_container_card_price span {
    font-size: clamp(2.8rem, 7vw, 3.7rem);
    /* font-family: 'Luxurious Roman', serif; */
    font-family: "Lato", sans-serif !important;
}




@media screen and (min-width: 550px) {

    .tarif_card {
        width: 90%;
        max-width: 48rem;
    }
}


@media screen and (min-width: 650px) {

    .tarif_card h2 {
        margin-bottom: 3rem;
    }
}



@media screen and (min-width: 750px) {

    .tarif_card {
        min-height: 36rem;
        max-height: 50rem;
        /* min-width: 59rem; */
        max-width: 60rem;
        position: relative;
    }

    .tarif_card ul {
        position: relative;
        left: 26%;
    }

    .tarif_page_prestation_container_card_price {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (min-width: 1100px) {

    .prestation_container_card {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .tarif_card {
        min-width: 34rem;
        width: 30%;
        min-height: 48rem;
      
    }

    .tarif_card ul {
        left: 0;
    }
}


@media screen and (min-width: 1500px) {

    .tarif_page_prestation_container_card_price {
        bottom: 5rem;
    }
    

    .prestation_container {
        margin: auto;
        max-width: 194rem;   
    }

    .tarifs_page .title_page {
        margin-bottom: 12rem;
    }


    .tarif_card {
        min-width: 46rem;
        height: 40rem;
        min-height: 50vh;

    }

    .tarif_card li p {
        font-size: clamp(1.4rem, 0.9vw, 1.7rem);
    }
}


@keyframes opacity-submenu-component {
    from{
        opacity: 0;
    }
  
    to{
        opacity: 1;
    }
  }
  



