.video_content {
    margin-top: 0;
    padding-top: 0;;
  }

  .button_to_video_text {
    text-align: center;
    margin: 5rem auto 7rem auto;
    padding: 2rem 1.5rem;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    width: 60%;
    max-width: 50rem;
  }
  
  .video_body {
    width: 100%;
    margin-top: 5vw;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .video_page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin: auto;  
  }
  
  .video_page .stylist_name {
    margin-top: 5rem;
    font-size: 8rem;
  }
  
  .video_page .stylist_name::before {
    height: 1px;
    top: 6rem;
    width: 87%;
    max-width: 30rem;
  }
  
  .video_page .youtube_video {
    width: 100%;
    height: 22rem;
    border: 2px solid wheat;
    border-radius: 4px;
  }

  .video_page .youtube_video:first-child,
  .video_page .youtube_video:nth-child(2) {
    margin-bottom: 5rem;
  }
  
  .video_container {
    width: 100%;
  }
  
  .video_container_title {
    text-align: center;
    margin-bottom: 8rem;
    font-size: 3.5rem;
    letter-spacing: 0.4rem;
    color: wheat;
    font-size: 3.5rem;
    margin-bottom: 5rem;
    position: relative;
    font-family: "Lato", sans-serif;
    letter-spacing: 0.4rem;
  }
  .video_container_title::before {
    content: "";
    position: absolute;
    height: 2px;
    background-color: wheat;
    font-weight: 700;
    left: 50%;
    transform: translate(-50%, -5px);
    width: 22rem;
    bottom: -1rem;
  }
  
  .video_container_title:last-of-type {
    margin-top: 4rem;
  }
  
  @media screen and (min-width: 800px) {
    .video_page {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      flex-direction: row;      
    }
  
    .video_page .stylist_name {
      font-size: 10.5rem;
    }
  
    .video_page .stylist_name::before {
      top: 8rem;
    }
  
    .video_page .youtube_video {
      width: 44%;
      height: 22vw;
      border: 2px solid wheat;
    }
    
  }
  .video_container_title {
    margin-bottom: calc(7rem + 2vw);
  }
  

@media screen and (min-width: 1500px) {
    .introduce_video {
      width: 90%;
      margin: auto;
      padding-bottom: 9rem;
      object-fit: cover;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  
    .introduce_video::before {
      width: 140%;
      background-size: 140% ;
      background-repeat: no-repeat;
      opacity: 1;
    }
  
    .introduce_video .youtube_video {
      width: 89rem;
      height: 44rem;
      border: 3px solid wheat;
      margin: auto;
      align-items: space-between;
    }
  
  
    .button_to_video_text {
      margin-top: 10rem;
      width: 50rem;
    }
  }

  @media screen and (min-width: 2200px) {
    .video_body {
      width: 80%;
    }


  }