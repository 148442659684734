
/* Begin only hometest.jsx page */


.home_test_page {
    /* min-height: 100vh; */
    border-top: 1px solid wheat;
    border-bottom: 1px solid wheat;
    padding: 0rem 0 1rem 0;
    /* margin-bottom: 3rem; */
}

/* End only hometest.jsx page */


.card_portfolio_container {
    margin: 4rem 0.3rem;
}


.card_portfolio {
    width: 99%;
    margin: auto;
    aspect-ratio: 6 / 12 ;
}

.card_portfolio_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card_portfolio_img:first-child {
    position: relative;
    top: 3rem;
}


.slick-slider {
    margin: auto;
    width: 90%;
    max-width: 124rem;
}

.butons_control_container {
    margin-top: 6rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

button.button {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 2rem;
    border: 1px solid wheat;
    border-radius: 50%;
    padding: 9px;
    
    margin-bottom: 2rem;

}


.butons_control_container svg {
    fill: wheat;
    width: 1rem;
}

.slick-dots {
    position: relative;
    top: 3rem;
}

.slick-arrow::before {
 color: wheat;
}

.slick-dots li button {
    position: relative;
    color: white!important;
    opacity: 1;
}

.slick-dots li button::before  {
    width: 1rem;
    height: 1rem;    
    font-size: clamp(5px, 1vw, 7px);
    color: white!important;
    opacity: 1;
   

}

.slick-active, .slick-dots li button:hover, .slick-dots li button:focus, .slick-dots li button {
    color: white!important;
}

.slick-dots li.slick-active button::before {
    color: wheat!important;
    
}


@media screen and (min-width: 2000px) {


.slick-slider {
    margin: auto;
    width: 90%;
    max-width: 180rem;
}

}
