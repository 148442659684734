.core-section {
  text-align: left;
}

.img-icon {
  /* background-color: white; */
  /* background-image: linear-gradient(to right top, #ffffff, wheat, #fff4f7, #fff, #fff, #fff, #fff, #fff, #fff, #fff, #fff, wheat); */
  background: radial-gradient(ellipse farthest-corner at right bottom, #f8f2d5 0%, #fff5e0 8%, #ffeec9 30%, #ffedc3 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #ffefc1 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.feature-box-01 {
  position: relative;
  top: 0px;
  display: flex;
  align-items: flex-start;
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-border-opacity: 0.1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  transition-duration: 300ms;
  transition-timing-function: linear;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.feature-box-01 .icon {
  display: inline-flex;
  height: 60px;
  width: 60px;
  flex: 0 0 60px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
}

.feature-box-01 .feature-content {
  flex: 1 1 0%;
  padding-left: 1.25rem;
}

.feature-box-01 .feature-content h5 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.feature-box-02 {
  position: relative;
  top: 0px;
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-border-opacity: 0.1;
  /* background-color: rgb(255 255 255 / var(--tw-bg-opacity)); */
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));

  --tw-bg-opacity: 0.06;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 35px;
  transition-duration: 300ms;
  transition-timing-function: linear;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.feature-box-02 .icon {
  margin-bottom: 2rem;
  display: inline-flex;
  height: 35px;
  width: 35px;
  flex: 0 0 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-border-opacity: 0.1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  font-size: 30px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.feature-box-02 h5 {
  margin-bottom: 0.75rem;
  font-size: 1.40rem;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  .feature-box-03 .feature-img {
    height: 120px;
    width: 80px;
    flex: 0 0 120px;
  }
}

.feature-box-03 .feature-img img {
  width: auto;
  max-width: 100%;
}

.feature-box-03 .icons {
  position: absolute;
  top: 0px;
  right: 0.75rem;
  font-size: 6rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  opacity: 0.1;
}

.feature-box-03 .feature-content {
  flex: 1 1 0%;
  padding-left: 1.25rem;
}

.feature-box-03 .feature-content p {
  margin-bottom: 0.75rem;
}