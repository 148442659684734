
.photo_page_title {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: opacity-image-show-title;
    animation-duration: 0.2s;
}

.container_main_image {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10rem;
}


.container_image_show {
    width: 100%;
    margin: 4% auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    
    
}


.container_image_show:last-child {

    margin-bottom: 5rem;

}

.image_show_card {
    width: 48%;
    max-width: 25rem;
    border-right: 1px solid rgb(0, 0, 0);
    box-shadow: 0px 30px 30px black,
    0 -20px 30px black;
    border-radius: 0 4px 4px 4px;
    border-top: 1px solid wheat;
    border-bottom: 1px solid wheat;
    opacity: 0;
    transform-origin: center;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: opacity-image-show-card;
    animation-duration: 0.7s;
}


.show_frame_1 .image_show_card:nth-child(1) {
    animation-delay: 0.4s;
}

.show_frame_1 .image_show_card:nth-child(2) {
    animation-delay: 0.6s;
}

.show_frame_2 .image_show_card:nth-child(1) {
    animation-delay: 0.8s;
}

.show_frame_2 .image_show_card:nth-child(2) {
    animation-delay: 1s;
}


@keyframes opacity-image-show-title {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }

}


@keyframes opacity-image-show-card {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}

.show_frame_2 {
    position: relative;
    
}

.image_show_card:nth-child(2) {
    position: relative;
    z-index: 2;    
    top: 2rem;    
}

.image_show_card:nth-child(3) {
    position: relative;
    right: 1rem; 
    top: 4rem;
    z-index: 3;
}







/* @media screen and (min-width: 1500px) {

    .container_main_image {
        display: flex;
        flex-direction: row;
        margin: auto;
        margin-bottom: 20rem;
        position: relative;
        width: 90%;
       
        
    }


    .container_image_show {
        position: relative;
        width: 46%;
    }
    
    .container_image_show .image_show_card {
        top: 0;
        height: 72rem;
        width: 34rem;
        max-width: 40rem;
        left: 0;
        margin: 0 2rem;
        padding: 0.5rem;
        border: 1px solid wheat;

    }

    .show_frame_1 {
        position: relative;
        margin-right: 0;
        margin-left: 0;
        left: 4%;
        justify-content: flex-end;
    }

    .show_frame_2 {
        justify-content: flex-start;
        margin-left: 0;
        left: 4%;
    }



    } */
























    
    /* .container_image_show {
        width: 100%;
        margin: auto;
        margin-bottom: 5rem;
        border: 1px solid white;
        position: relative;
    }

    .container_image_show .image_show_card {
        transform: translateX(70%);
        transition: all 0.3s 0.3s ease-in-out;
    }

    .show_frame_1 {
        position: relative;
        justify-content: flex-start;
        
    }

    .image_show_card {
        display: flex;
        max-width: 25rem;
        width: 100%;
    }

    .show_frame_2 {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 20%;
    }

}


@media screen and (min-width: 1700px) {

    .show_frame_2 {
        display: flex;
        right: 18%;
    }

    .show_frame_2:nth-child(1) {
        top: 4rem;
    }

    .show_frame_2:nth-child(2) {
        top: 8rem;
    }

}
 */
