
.faq-page h1 {
    font-family: "Luxurious Roman", serif;
    font-size: clamp(1.4rem,2vw, 1.6rem);
}

.faq-element {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.4s ease-in-out, max-height 0.4s ease-in-out;
}

.faq-element.reveal {
    opacity: 1;
    max-height: 100px; 

}